// import 'babel-polyfill';
// import 'isomorphic-fetch';
import React from 'react'
import { StaticQuery, Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import { FaArrowRight } from 'react-icons/fa'
import { BsLifePreserver } from 'react-icons/bs'
import { IoMdImages, IoMdShareAlt } from 'react-icons/io'
import { MdPeople } from 'react-icons/md'

const Sections = (props) => {
  console.log('Sections - props')
  console.log(props)
  const allSections =
    props && props.sections && props.sections.edges
      ? props.sections.edges.sort((a, b) =>
          a.node.data.sort > b.node.data.sort ? 1 : -1
        )
      : []

  return allSections
    ? allSections.map((edge, i) => {
        let gradientColor
        switch (edge.node.data.color) {
          case 'green':
            gradientColor = 'algae'
            break
          case 'pink':
            gradientColor = 'sunset'
            break
          case 'blue':
            gradientColor = 'cumulus'
            break
          default:
            gradientColor = 'cumulus'
        }
        if (edge.node.data.color)
          return (
            <div class="support-home-section-callout p-2 m-1 d-flex flex-column align-items-center justify-content-center w-100">
              <Link
                to={`/section/${edge.node.data.url}`}
                class={`p-2 px-3 d-flex flex-row align-items-center justify-content-center border-1-${edge.node.data.color}-3 gradient-callout-${gradientColor} w-75`}
              >
                <div class="callout-icon">
                  {edge.node.data.catid === 'access' ? (
                    <MdPeople size={24} color="#ffffff" />
                  ) : null}
                  {edge.node.data.catid === 'working' ? (
                    <IoMdImages size={24} color="#ffffff" />
                  ) : null}
                  {edge.node.data.catid === 'follow' ? (
                    <IoMdShareAlt size={24} color="#ffffff" />
                  ) : null}
                </div>
                <div class="ml-2 text-center white-1">
                  {edge.node.data.title}
                </div>
              </Link>
            </div>
          )
      })
    : null
}
const Snippet = (props) => (
  <StaticQuery
    query={graphql`
      query {
        featureSupport: file(
          relativePath: {
            eq: "stock/unsplash/christina-wocintechchat-com-1ceO7hWQ8xg-unsplash.jpg"
          }
        ) {
          childImageSharp {
            fixed(width: 260, height: 200, cropFocus: WEST) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `}
    render={(data) => (
      <section class="pricing my-5">
        <div class="container">
          <div class="row d-flex align-items-stretch justify-content-center">
            <div class="col-lg-4">
              <div class="card mb-5 mb-lg-0">
                <div class="card-title gradient-callout-overcast round-corners-top py-2">
                  <h5 class="card-title text-center white-1 pt-2">
                    Getting started
                  </h5>
                </div>
                <div class="card-body d-flex flex-column">
                  <p class="text-center font-weight-light">
                    Get started creating on-brand, customizable social content
                    from templates
                  </p>
                  <div class="support-sections-list d-flex flex-row flex-wrap align-items-center justify-content-center mb-4">
                    {/* <div class="p-2 m-1 d-flex flex-column align-items-center justify-content-center">
                    <Link
                      to="/article/sign-up"
                      class="p-2 px-3 d-flex flex-row align-items-center justify-content-center border-1-blue-1"
                    >
                      <IoMdImages size={24} />
                      <div class="ml-2 text-center">Working with images</div>
                    </Link>
                  </div>
                  <div class="p-2 m-1 d-flex flex-column align-items-center justify-content-center">
                    <Link
                      to="/article/sign-up"
                      class="p-2 px-3 d-flex flex-row align-items-center justify-content-center border-1-blue-1"
                    >
                      <AiOutlineLineChart size={24} />
                      <div class="ml-2 text-center">Enabling sales teams</div>
                    </Link>
                  </div> */}
                    {Sections(props)}
                  </div>
                  <Link to="/articles" class="btn btn-primary btn-cta mt-auto">
                    See all articles <FaArrowRight class="btn-arrow" />
                  </Link>
                </div>
              </div>
            </div>
            <div class="col-lg-4 mt-3 mt-lg-0">
              <div class="card mb-5 mb-lg-0">
                <div class="card-title gradient-callout-overcast round-corners-top py-2">
                  <h5 class="card-title text-center white-1 pt-2">
                    Contact support
                  </h5>
                </div>
                <div class="card-body d-flex flex-column align-items-center">
                  <p class="text-center font-weight-light">
                    Access help and guidance on how to use Storylava.
                  </p>
                  <Img
                    className="round-corners mb-2"
                    fixed={data.featureSupport.childImageSharp.fixed}
                  />

                  <a
                    href="mailto:support@storylava.com"
                    class="btn btn-primary btn-cta btn-block mt-auto"
                  >
                    Email support <FaArrowRight class="btn-arrow" />
                  </a>
                </div>
              </div>
            </div>
            {/* <div class="col-lg-4">
          <div class="card mt-3 mt-lg-0 mb-5 mb-lg-0">
            <div class="card-body d-flex flex-column">
              <h5 class="card-title text-center  sky-1">Organizations</h5>
              <hr />
              <p class="text-center font-weight-light">
                Use onboarding resources and customized support to make your
                investment a success
              </p>
              <Link to="/request-demo/" class="btn btn-primary btn-cta mt-auto">
                Request demo <FaArrowRight class="btn-arrow" />
              </Link>
            </div>
          </div>
        </div> */}
          </div>
        </div>
      </section>
    )}
  />
)

export default Snippet
